import React from 'react'
import {
    Box,
    Text,
    Flex,
    ChakraProvider,
    Button,
    Spacer,
    Link,
} from '@chakra-ui/react'
import Header from './components/Header'
import GLink from 'gatsby-link'

function AboutPage() {
    return (
        <ChakraProvider>
            <Header />
            <Flex
                align={'center'}
                justify={'center'}
                h={'100vh'}
                backgroundColor={'#1A1C2B'}
            >
                <Box w={'100vw'}>
                    <Box p={8} m={'auto'} w={'30vw'}>
                        <Text fontSize="xl" color={'white'} align={'center'}>
                            OUR ABOUT PAGE
                        </Text>
                    </Box>
                    <Flex m={'auto'} justify={'center'}>
                    </Flex>
                </Box>
            </Flex>
        </ChakraProvider>
    )
}

export default AboutPage
